import { PokemonType } from "./pokeHelper";

export const pokedex: Array<PokemonType> = [
  {
    id: 1,
    name: "Bulbasaur",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/001.png",
  },
  {
    id: 2,
    name: "Ivysaur",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/002.png",
  },
  {
    id: 3,
    name: "Venusaur",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/003.png",
  },
  {
    id: 4,
    name: "Charmander",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/004.png",
  },
  {
    id: 5,
    name: "Charmeleon",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/005.png",
  },
  {
    id: 6,
    name: "Charizard",
    types: ["Fire", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/006.png",
  },
  {
    id: 7,
    name: "Squirtle",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/007.png",
  },
  {
    id: 8,
    name: "Wartortle",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/008.png",
  },
  {
    id: 9,
    name: "Blastoise",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/009.png",
  },
  {
    id: 10,
    name: "Caterpie",
    types: ["Bug"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/010.png",
  },
  {
    id: 11,
    name: "Metapod",
    types: ["Bug"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/011.png",
  },
  {
    id: 12,
    name: "Butterfree",
    types: ["Bug", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/012.png",
  },
  {
    id: 13,
    name: "Weedle",
    types: ["Bug", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/013.png",
  },
  {
    id: 14,
    name: "Kakuna",
    types: ["Bug", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/014.png",
  },
  {
    id: 15,
    name: "Beedrill",
    types: ["Bug", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/015.png",
  },
  {
    id: 16,
    name: "Pidgey",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/016.png",
  },
  {
    id: 17,
    name: "Pidgeotto",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/017.png",
  },
  {
    id: 18,
    name: "Pidgeot",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/018.png",
  },
  {
    id: 19,
    name: "Rattata",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/019.png",
  },
  {
    id: 20,
    name: "Raticate",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/020.png",
  },
  {
    id: 21,
    name: "Spearow",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/021.png",
  },
  {
    id: 22,
    name: "Fearow",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/022.png",
  },
  {
    id: 23,
    name: "Ekans",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/023.png",
  },
  {
    id: 24,
    name: "Arbok",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/024.png",
  },
  {
    id: 25,
    name: "Pikachu",
    types: ["Electric"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/025.png",
  },
  {
    id: 26,
    name: "Raichu",
    types: ["Electric"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/026.png",
  },
  {
    id: 27,
    name: "Sandshrew",
    types: ["Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/027.png",
  },
  {
    id: 28,
    name: "Sandslash",
    types: ["Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/028.png",
  },
  {
    id: 29,
    name: "Nidoran♀",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/029.png",
  },
  {
    id: 30,
    name: "Nidorina",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/030.png",
  },
  {
    id: 31,
    name: "Nidoqueen",
    types: ["Poison", "Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/031.png",
  },
  {
    id: 32,
    name: "Nidoran♂",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/032.png",
  },
  {
    id: 33,
    name: "Nidorino",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/033.png",
  },
  {
    id: 34,
    name: "Nidoking",
    types: ["Poison", "Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/034.png",
  },
  {
    id: 35,
    name: "Clefairy",
    types: ["Fairy"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/035.png",
  },
  {
    id: 36,
    name: "Clefable",
    types: ["Fairy"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/036.png",
  },
  {
    id: 37,
    name: "Vulpix",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/037.png",
  },
  {
    id: 38,
    name: "Ninetales",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/038.png",
  },
  {
    id: 39,
    name: "Jigglypuff",
    types: ["Normal", "Fairy"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/039.png",
  },
  {
    id: 40,
    name: "Wigglytuff",
    types: ["Normal", "Fairy"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/040.png",
  },
  {
    id: 41,
    name: "Zubat",
    types: ["Poison", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/041.png",
  },
  {
    id: 42,
    name: "Golbat",
    types: ["Poison", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/042.png",
  },
  {
    id: 43,
    name: "Oddish",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/043.png",
  },
  {
    id: 44,
    name: "Gloom",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/044.png",
  },
  {
    id: 45,
    name: "Vileplume",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/045.png",
  },
  {
    id: 46,
    name: "Paras",
    types: ["Bug", "Grass"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/046.png",
  },
  {
    id: 47,
    name: "Parasect",
    types: ["Bug", "Grass"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/047.png",
  },
  {
    id: 48,
    name: "Venonat",
    types: ["Bug", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/048.png",
  },
  {
    id: 49,
    name: "Venomoth",
    types: ["Bug", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/049.png",
  },
  {
    id: 50,
    name: "Diglett",
    types: ["Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/050.png",
  },
  {
    id: 51,
    name: "Dugtrio",
    types: ["Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/051.png",
  },
  {
    id: 52,
    name: "Meowth",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/052.png",
  },
  {
    id: 53,
    name: "Persian",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/053.png",
  },
  {
    id: 54,
    name: "Psyduck",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/054.png",
  },
  {
    id: 55,
    name: "Golduck",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/055.png",
  },
  {
    id: 56,
    name: "Mankey",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/056.png",
  },
  {
    id: 57,
    name: "Primeape",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/057.png",
  },
  {
    id: 58,
    name: "Growlithe",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/058.png",
  },
  {
    id: 59,
    name: "Arcanine",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/059.png",
  },
  {
    id: 60,
    name: "Poliwag",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/060.png",
  },
  {
    id: 61,
    name: "Poliwhirl",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/061.png",
  },
  {
    id: 62,
    name: "Poliwrath",
    types: ["Water", "Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/062.png",
  },
  {
    id: 63,
    name: "Abra",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/063.png",
  },
  {
    id: 64,
    name: "Kadabra",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/064.png",
  },
  {
    id: 65,
    name: "Alakazam",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/065.png",
  },
  {
    id: 66,
    name: "Machop",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/066.png",
  },
  {
    id: 67,
    name: "Machoke",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/067.png",
  },
  {
    id: 68,
    name: "Machamp",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/068.png",
  },
  {
    id: 69,
    name: "Bellsprout",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/069.png",
  },
  {
    id: 70,
    name: "Weepinbell",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/070.png",
  },
  {
    id: 71,
    name: "Victreebel",
    types: ["Grass", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/071.png",
  },
  {
    id: 72,
    name: "Tentacool",
    types: ["Water", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/072.png",
  },
  {
    id: 73,
    name: "Tentacruel",
    types: ["Water", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/073.png",
  },
  {
    id: 74,
    name: "Geodude",
    types: ["Rock", "Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/074.png",
  },
  {
    id: 75,
    name: "Graveler",
    types: ["Rock", "Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/075.png",
  },
  {
    id: 76,
    name: "Golem",
    types: ["Rock", "Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/076.png",
  },
  {
    id: 77,
    name: "Ponyta",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/077.png",
  },
  {
    id: 78,
    name: "Rapidash",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/078.png",
  },
  {
    id: 79,
    name: "Slowpoke",
    types: ["Water", "Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/079.png",
  },
  {
    id: 80,
    name: "Slowbro",
    types: ["Water", "Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/080.png",
  },
  {
    id: 81,
    name: "Magnemite",
    types: ["Electric", "Steel"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/081.png",
  },
  {
    id: 82,
    name: "Magneton",
    types: ["Electric", "Steel"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/082.png",
  },
  {
    id: 83,
    name: "Farfetch'd",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/083.png",
  },
  {
    id: 84,
    name: "Doduo",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/084.png",
  },
  {
    id: 85,
    name: "Dodrio",
    types: ["Normal", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/085.png",
  },
  {
    id: 86,
    name: "Seel",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/086.png",
  },
  {
    id: 87,
    name: "Dewgong",
    types: ["Water", "Ice"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/087.png",
  },
  {
    id: 88,
    name: "Grimer",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/088.png",
  },
  {
    id: 89,
    name: "Muk",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/089.png",
  },
  {
    id: 90,
    name: "Shellder",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/090.png",
  },
  {
    id: 91,
    name: "Cloyster",
    types: ["Water", "Ice"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/091.png",
  },
  {
    id: 92,
    name: "Gastly",
    types: ["Ghost", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/092.png",
  },
  {
    id: 93,
    name: "Haunter",
    types: ["Ghost", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/093.png",
  },
  {
    id: 94,
    name: "Gengar",
    types: ["Ghost", "Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/094.png",
  },
  {
    id: 95,
    name: "Onix",
    types: ["Rock", "Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/095.png",
  },
  {
    id: 96,
    name: "Drowzee",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/096.png",
  },
  {
    id: 97,
    name: "Hypno",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/097.png",
  },
  {
    id: 98,
    name: "Krabby",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/098.png",
  },
  {
    id: 99,
    name: "Kingler",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/099.png",
  },
  {
    id: 100,
    name: "Voltorb",
    types: ["Electric"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/100.png",
  },
  {
    id: 101,
    name: "Electrode",
    types: ["Electric"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/101.png",
  },
  {
    id: 102,
    name: "Exeggcute",
    types: ["Grass", "Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/102.png",
  },
  {
    id: 103,
    name: "Exeggutor",
    types: ["Grass", "Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/103.png",
  },
  {
    id: 104,
    name: "Cubone",
    types: ["Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/104.png",
  },
  {
    id: 105,
    name: "Marowak",
    types: ["Ground"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/105.png",
  },
  {
    id: 106,
    name: "Hitmonlee",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/106.png",
  },
  {
    id: 107,
    name: "Hitmonchan",
    types: ["Fighting"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/107.png",
  },
  {
    id: 108,
    name: "Lickitung",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/108.png",
  },
  {
    id: 109,
    name: "Koffing",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/109.png",
  },
  {
    id: 110,
    name: "Weezing",
    types: ["Poison"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/110.png",
  },
  {
    id: 111,
    name: "Rhyhorn",
    types: ["Ground", "Rock"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/111.png",
  },
  {
    id: 112,
    name: "Rhydon",
    types: ["Ground", "Rock"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/112.png",
  },
  {
    id: 113,
    name: "Chansey",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/113.png",
  },
  {
    id: 114,
    name: "Tangela",
    types: ["Grass"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/114.png",
  },
  {
    id: 115,
    name: "Kangaskhan",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/115.png",
  },
  {
    id: 116,
    name: "Horsea",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/116.png",
  },
  {
    id: 117,
    name: "Seadra",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/117.png",
  },
  {
    id: 118,
    name: "Goldeen",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/118.png",
  },
  {
    id: 119,
    name: "Seaking",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/119.png",
  },
  {
    id: 120,
    name: "Staryu",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/120.png",
  },
  {
    id: 121,
    name: "Starmie",
    types: ["Water", "Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/121.png",
  },
  {
    id: 122,
    name: "Mr.",
    types: ["Psychic", "Fairy"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/122.png",
  },
  {
    id: 123,
    name: "Scyther",
    types: ["Bug", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/123.png",
  },
  {
    id: 124,
    name: "Jynx",
    types: ["Ice", "Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/124.png",
  },
  {
    id: 125,
    name: "Electabuzz",
    types: ["Electric"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/125.png",
  },
  {
    id: 126,
    name: "Magmar",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/126.png",
  },
  {
    id: 127,
    name: "Pinsir",
    types: ["Bug"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/127.png",
  },
  {
    id: 128,
    name: "Tauros",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/128.png",
  },
  {
    id: 129,
    name: "Magikarp",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/129.png",
  },
  {
    id: 130,
    name: "Gyarados",
    types: ["Water", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/130.png",
  },
  {
    id: 131,
    name: "Lapras",
    types: ["Water", "Ice"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/131.png",
  },
  {
    id: 132,
    name: "Ditto",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/132.png",
  },
  {
    id: 133,
    name: "Eevee",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/133.png",
  },
  {
    id: 134,
    name: "Vaporeon",
    types: ["Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/134.png",
  },
  {
    id: 135,
    name: "Jolteon",
    types: ["Electric"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/135.png",
  },
  {
    id: 136,
    name: "Flareon",
    types: ["Fire"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/136.png",
  },
  {
    id: 137,
    name: "Porygon",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/137.png",
  },
  {
    id: 138,
    name: "Omanyte",
    types: ["Rock", "Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/138.png",
  },
  {
    id: 139,
    name: "Omastar",
    types: ["Rock", "Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/139.png",
  },
  {
    id: 140,
    name: "Kabuto",
    types: ["Rock", "Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/140.png",
  },
  {
    id: 141,
    name: "Kabutops",
    types: ["Rock", "Water"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/141.png",
  },
  {
    id: 142,
    name: "Aerodactyl",
    types: ["Rock", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/142.png",
  },
  {
    id: 143,
    name: "Snorlax",
    types: ["Normal"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/143.png",
  },
  {
    id: 144,
    name: "Articuno",
    types: ["Ice", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/144.png",
  },
  {
    id: 145,
    name: "Zapdos",
    types: ["Electric", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/145.png",
  },
  {
    id: 146,
    name: "Moltres",
    types: ["Fire", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/146.png",
  },
  {
    id: 147,
    name: "Dratini",
    types: ["Dragon"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/147.png",
  },
  {
    id: 148,
    name: "Dragonair",
    types: ["Dragon"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/148.png",
  },
  {
    id: 149,
    name: "Dragonite",
    types: ["Dragon", "Flying"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/149.png",
  },
  {
    id: 150,
    name: "Mewtwo",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/150.png",
  },
  {
    id: 151,
    name: "Mew",
    types: ["Psychic"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/151.png",
  },
  {
    id: 152,
    name: "Meltan",
    types: ["Steel"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/808.png",
  },
  {
    id: 153,
    name: "Melmetal",
    types: ["Steel"],
    src: "https://www.serebii.net/letsgopikachueevee/pokemon/809.png",
  },
];
